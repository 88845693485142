@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");


.App {
  
  font-family: "Press Start 2P", "VT323";
  color: white;
}

*{
  box-sizing: border-box;
}

.overlay{
  opacity: 0.85;
  width: 100%;
 
  z-index: 10;
  top: 0;
  left: 0;
 
}

.moving-background{
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/background/back.gif");
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 30%;
  background-size: 100% 100%;
}